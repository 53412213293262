const statistic = {
    global: {
        title: {
            mySubscribers: 'Mes abonnés',
            myFollowers: 'Mes followers',
        },
        empty: "Looks like you don't have any subscribers yet",
        subscriber: 'Abonné',
        subscribedSince: 'abboné depuis le',
    },
    columns: {
        name: 'Nom',
        subscribedAt: 'Abonné depuis',
        email: 'Email',
    }
}

export default statistic
const statistic = {
    global: {
        title: {
            mySubscribers: 'Mis suscriptores',
            myFollowers: 'My followers',
        },
        empty: "Looks like you don't have any subscribers yet",
        subscriber: 'Subscriber',
        subscribedSince: 'suscrito desde',
    },
    columns: {
        name: 'Nombre',
        subscribedAt: 'Suscrito en',
        followedAt: 'Followed at',
        email: 'Correo',
    }
}

export default statistic
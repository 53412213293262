import admin from './_admin'
import auth from './_auth'
import calendar from './_calendar'
import creator from './_creator'
import dashboard from './_dashboard'
import earlyAccess from './_earlyAccess'
import favorite from './_favorite'
import gallery from './_gallery'
import globals from './_globals'
import guest from './_guest'
import home from './_home'
import masterclass from './_masterclass'
import media from './_media'
import messenger from './_messenger'
import page from './_page'
import progress from './_progress'
import recipe from './_recipe'
import statistic from './_statistic'
import tag from './_tag'
import user from './_user'
import workout from './_workout'
import liveEvents from './_liveEvents'


const fr = {
    admin,
    auth,
    calendar,
    creator,
    dashboard,
    earlyAccess,
    favorite,
    gallery,
    globals,
    guest,
    home,
    masterclass,
    media,
    messenger,
    page,
    progress,
    recipe,
    statistic,
    tag,
    user,
    workout,
    liveEvents,
}

export default fr